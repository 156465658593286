import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refProductListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'main_image_url', label: '', sortable: false },
    { key: 'ordering', label: 'Sıra', sortable: true },
    { key: 'is_draft', label: 'Durum', sortable: true },
    { key: 'name', label: 'Ürün Adı', sortable: true },
    { key: 'stocks', label: 'Stok', sortable: false },
    { key: 'price', label: 'Fiyat', sortable: false },
    { key: 'creator', label: 'Kaynak', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalProducts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('is_draft')
  const isSortDirDesc = ref(false)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const categoriesFilter = ref(null)
  const dataMeta = computed(() => {
    const localItemsCount = refProductListTable.value ? refProductListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    }
  })

  const refetchData = () => {
    refProductListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter, categoriesFilter], () => {
    refetchData()
  })

  const fetchProducts = (ctx, callback) => {
    store
      .dispatch('app-product/fetchProducts', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
        categoriesFilter: categoriesFilter.value,
      })
      .then(response => {
        const { products } = response.data.data
        const { total } = response.data.meta

        callback(products)
        totalProducts.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching product list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    fetchProducts,
    tableColumns,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductListTable,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    categoriesFilter,
  }
}
